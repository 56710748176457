@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ UltraLight\ Az.otf");
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ ExtraLight\ Az.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ Regular\ Az.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ Medium\ Az.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ Semi\ Bold\ Az.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ Bold\ Az.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "sofiapro";
  src: url("fonts/sofia-pro/Sofia\ Pro\ Black\ Az.otf");
  font-weight: 900;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  font-family: "sofiapro", sans-serif;
  font-weight: 400;
  max-height: 100%;
}

/* SCROLLBARS STYLING */

/* LIGHT MODE */
html * {
  scrollbar-width: thin;
  scrollbar-color: #b6bfc0 #e4e4e4;
}

html *::-webkit-scrollbar {
  width: 10px;
}

html *::-webkit-scrollbar-track {
  background: #e4e4e4;
}

html *::-webkit-scrollbar-thumb {
  background-color: #b6bfc0;
  border-radius: 20px;
}

/* DARK MODE */
html.dark * {
  scrollbar-width: thin;
  scrollbar-color: #6e7e81 #3b424d;
}

/* Works on Chrome, Edge, and Safari */
html.dark *::-webkit-scrollbar {
  width: 10px;
}

html.dark *::-webkit-scrollbar-track {
  background: #3b424d;
}

html.dark *::-webkit-scrollbar-thumb {
  background-color: #6e7e81;
  border-radius: 20px;
}

/* END SCROLLBARS STYLING */
